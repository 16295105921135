import React, {useContext} from 'react';
import Layout from '../components/layout';
import SignIn from '../components/signinui';

const SignInScreen = () => {
  return (
    <Layout>
      <div className="container text-center">
        <h1>My App</h1>
        <pre></pre>
        <p>Please sign-in:</p>
        {/* <SignIn/> */}
      </div>
    </Layout>
  );
};


export default SignInScreen
;
