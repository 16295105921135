import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Turtle from '../svg/happyturtle.svg';

const Header = ({siteTitle, TailwindChange}) => (
  <header className={'flex text-white font-semibold py-1 '+TailwindChange}>
    <div className="flex container max-w-5xl justify-between">
      <div className="m-2 md:w-4/5 w-1/2">
        <Link
          to="/"
          className = "flex flex-row no-underline">
          <Turtle className="flex fill-current text-white h-5 mr-2"/>
          <span className="hidden md:block no-underline text-white text-lg">
            {siteTitle}
          </span>
        </Link>
      </div>
      {/* <div className="m-2">
        <Link to="articles" className="no-underline">
          <span className="no-underline text-white text-md">Articles</span>
        </Link>
      </div>
      <div className="m-2">
        <span className="no-underline text-white text-md">Login</span>
      </div> */}
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  TailwindChange: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  TailwindChange: '',
};

export default Header;
